import {BrowserRouter as Router, Routes, Route, useNavigate, Link} from 'react-router-dom';
import { useState } from 'react';

import MainTemplate from '../components/mainTemplate';
import ListTemplate from '../components/listTemplate';
import { ReactComponent as LocationsIcon } from '../assets/itw_location_icon.svg';

import '../css/App.css';

import { useQuery } from '@apollo/client';
import GET_ALL_LOCATIONS from '../queries/getAllLocations';

import {useUser} from '../context/userContext';

const Location = () => {
    const pageTitle = "Locations";

    const navigate = useNavigate();
    const role = useUser().userRole;

    const [operationTypeFilter, setOperationTypeFilter] = useState<string | null>(null);
    const [outlierFilter, setOutlierFilter] = useState<string | null>(null);
    const [stateFilter, setStateFilter] = useState<string | null>(null);


    const { loading, error, data } = useQuery(GET_ALL_LOCATIONS, {
        variables: {
            filter: {
                operationType: operationTypeFilter,
                outlierCount: outlierFilter, 
                addressState: stateFilter
            },
            skip: 0
        }
    });

    interface Location {
        _id: string;
        name: string;
        operationType: string;
        fitForDutyPercent: string;
        roadReadyPercent: number;
        numFtEmployees: number;
        numPtEmployees: number;
        addressState: string;
        locManagerName: string;
    }

    const uniqueStates = data ? [...new Set(data.getAllLocations.map((location: Location) => location.addressState))] : [];
    const stateMenuItems = uniqueStates.map(state => ({ label: state }));

    const menuItems = {
        menuItemsTwo: [
            { label: 'All Operation Types' },
            { label: 'Cultivation' },
            { label: 'Distribution' },
            { label: 'Manufacturing' },
            { label: 'Retail' },
        ],
        menuItemsThree: [
            { label: 'All Outliers' },
            { label: 'Bottom 10' },
            { label: 'Bottom 25' },
            { label: 'Top 10' },
            { label: 'Top 25' }
        ],
        menuItemsStates: [
            { label: 'All States' },
            ...stateMenuItems
        ]
    };

    const dropDownData = [
        {
            title: "OPERATION TYPES",
            menuItems: menuItems.menuItemsTwo
        },
        {
            title: "OUTLIERS",
            menuItems: menuItems.menuItemsThree
        },
        {
            title: "STATES",
            menuItems: menuItems.menuItemsStates
        }
    ];

    const columnDefs = [
        { field: 'Locations', flex: 1, cellClass: 'underline-location' },
        { field: 'Operation Type', flex: 1 },
        { field: 'State', flex: 1 },
        { field: 'Full Time Employees', flex: 1 },
        { field: 'Part Time Employees', flex: 1 },
        { field: 'Managers', flex: 1 },
    ];

    if (error) return <p>Error: {error.message}</p>;

    const handleOperationTypeSelection = (operationType: string) => {
        if (operationType === "All Operation Types") {
            setOperationTypeFilter(null);
        } else {
            setOperationTypeFilter(operationType);
        }
    };

    const handleOutlierSelection = (outlierCount: string) => {
        if (outlierCount === "All Outliers") {
            setOutlierFilter(null);
        } else {
            setOutlierFilter(outlierCount);
        }
    };

    const handleStateSelection = (addressState: string) => {
        if (addressState === "All States") {
            setStateFilter(null);
        } else {
            setStateFilter(addressState);
        }
    };    

    // Filter reset logic
    const handleFilterReset = () => {
        setOperationTypeFilter(null);
        setOutlierFilter(null);
        setStateFilter(null);
    };

    const sortLocationsAlphabetically = (locations: Location[]) => {
        const locationsCopy = [...locations];  // Create a shallow copy of the array
        return locationsCopy.sort((a, b) => a.name.localeCompare(b.name));
    };

    const rowDataOne = data ? sortLocationsAlphabetically(data.getAllLocations).map((location: Location) => {
        return {
            '_id': location._id,
            'Locations': location.name,
            'Operation Type': location.operationType,
            'State': location.addressState,
            'Full Time Employees': location.numFtEmployees,
            'Part Time Employees': location.numPtEmployees,
            //change this to location.managers when managers are available
            'Managers': location.locManagerName
        }
    }) : [];

    const handleRowClick = (event: any) => {
        const id = event.data._id;
        navigate(`/detailLocation/${id}`);
    }

    return (
        <div>
            <MainTemplate />
            <ListTemplate
                rowDataOne={rowDataOne}
                handleRowClick={handleRowClick}
                // pageTitle={<Link to="/location">{pageTitle}</Link>}
                pageTitle ={pageTitle}
                columnDefs={columnDefs}
                dropDownData={dropDownData}
                onOperationTypeSelected={handleOperationTypeSelection}
                onOutlierSelection={handleOutlierSelection}
                onStateSelected={handleStateSelection}
                handleFilterReset={handleFilterReset}
                mainIcon={<LocationsIcon />}
            />
            {loading ? (
                <p>Loading...</p>
            ) : (
                <p>list</p>
            )}
                </div>

    );
}

export default Location;
