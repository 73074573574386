import MainTemplate from '../components/mainTemplate';
import HorizontalNavBar from '../components/horizontalNavBar';
import PageBackButton from '../components/pageBackButton';
import PageTitle from '../components/pageTitle';
import InformationComponent from '../components/displayData';
import RightSlideOver3 from '../components/rightSlideOverFeed3';
import SlideOverNav from '../components/slideOverNav';
import BadgeFlatWithDot from '../components/badgeFlatWithDot';
import AddButtonDetailDrivers from '../components/addButtonDetailDrivers';
import BaseSlideOver from '../components/baseSlideOver';

import { ReactComponent as EmployeesIcon } from '../assets/employees-list.svg';

import type { NavBarItem } from '../components/horizontalNavBar';

import { CheckIcon, XMarkIcon, DocumentIcon, EnvelopeIcon } from '@heroicons/react/20/solid';
import '../css/detailEmployee.css';

import { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import GET_EMPLOYEE from '../queries/getEmployee';
import {UPDATE_EMPLOYEE} from '../queries/updateEmployee';
import { GET_LOGS_BY_EMPLOYEE_ID } from '../queries/getLogsByEmployeeId';

import formatDate from '../helpers/formatDate'; 
import Table from '../components/table';
import DocumentUrlComponent from '../helpers/fetchPreSignedURL';
import { set } from 'date-fns';
import { ColDef } from 'ag-grid-community';

const EmployeeDetail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialView = queryParams.get('view')?.replace(/_/g, ' ') || 'PROFILE';
    const [currentView, setCurrentView] = useState(initialView);
    const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [activeView, setActiveView] = useState('driverOnboarding');
    const [addActiveButton, setAddActiveButton] = useState(false);
    const [isUploadSlideOverOpen, setIsUploadSlideOverOpen] = useState(false);

    const nav = useNavigate();
    const { id } = useParams();
    const employeeId = id
    
    const { loading: loadingEmployee, error: errorEmployee, data: dataEmployee, refetch } = useQuery(GET_EMPLOYEE, {
        variables: { id: id },
    });
    const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);

    const { data, loading, error } = useQuery(GET_LOGS_BY_EMPLOYEE_ID, {
        variables: { employeeId },
      });
      if (loading) {
        console.log('Loading logs...');
      }
      
      if (error) {
        console.error('Error fetching logs:', error);
      }

        let columnDefsRecords: ColDef<any, any>[] | ({ headerName: string; field: string; sortable: boolean; flex: number; valueFormatter?: undefined; } | { headerName: string; field: string; sortable: boolean; flex: number; valueFormatter: (params: { value: string; }) => string; })[] = [];
        let rowDataRecords = [];
      if (data) {
        // Define column headers based on log fields
        columnDefsRecords = [
          { headerName: 'Action', field: 'action', sortable: true, flex: 1 },
          { headerName: 'Description', field: 'description', sortable: true, flex: 2 },
          { headerName: 'Status', field: 'status', sortable: true, flex: 1 },
          { headerName: 'Timestamp', field: 'timestamp', sortable: true, flex: 1, valueFormatter: (params: { value: string; }) => new Date(parseInt(params.value)).toLocaleString() },
          { headerName: 'User ID', field: 'user._id', sortable: true, flex: 1 },
        ];
      
        // Map the logs to create row data
        rowDataRecords = data.getLogsByEmployeeId.map((log: { action: any; description: any; status: any; timestamp: any; user: { _id: any; }; }) => ({
          action: log.action,
          description: log.description,
          status: log.status,
          timestamp: log.timestamp,
          'user._id': log.user._id,  
        }));
      }

    if (loadingEmployee) return <div>Loading...</div>;
    if (errorEmployee) return <div>Error! {errorEmployee.message}</div>;
    if (!dataEmployee.getEmployeeById || !dataEmployee.getEmployeeById.program || dataEmployee.getEmployeeById.program.length === 0) {
        return <div>No Employee Data Available</div>;
    }

    const mapToEmployeeInput = (data:any) => {
        const input: {
            vehicleReference: any;
            relationshipToEmployee: any;
            emergencyContactName: any;
            emergencyContactPhone: any;
            driversLicenseExpiration: string;
            driversLicenseNum: any;
            addressZip: any;
            addressState: any;
            addressCity: any;
            addressStreet: any;
            emailPersonal: any;
            employmentStatus: any;
            // stopDate: string | null;
            hireDate: string;
            maritalStatus: any;
            dob: string;
            gender: any;
            position: any; firstName?: string, lastName?: string 
        } = {
            position: undefined,
            gender: undefined,
            dob: '',
            maritalStatus: undefined,
            hireDate: '',
            // stopDate: null,
            emailPersonal: undefined,
            employmentStatus: undefined,
            addressZip: undefined,
            addressState: undefined,
            addressCity: undefined,
            addressStreet: undefined,
            driversLicenseExpiration: '',
            driversLicenseNum: undefined,
            relationshipToEmployee: undefined,
            emergencyContactName: undefined,
            emergencyContactPhone: undefined,
            vehicleReference: undefined
        };
    
        data.forEach((section:any) => {
            section.data.forEach(({ label, value }: { label: string, value: any }) => {
                switch (label) {
                    case 'Employee Name':
                        const [firstName, lastName] = value.split(' ');
                        input.firstName = firstName;
                        input.lastName = lastName;
                        break;
                    case 'Position':
                        input.position = value;
                        break;
                    case 'Gender':
                        input.gender = value;
                        break;
                    case 'Date of Birth':
                        input.dob = new Date(value).toISOString();  // Format if necessary
                        break;
                    case 'Marital Status':
                        input.maritalStatus = value;
                        break;
                    case 'Hire Date':
                        input.hireDate = value;
                        break;
                    // case 'Stop Date':
                    //     input.stopDate = value;
                    //     break;
                    case 'Employment Status':
                        input.employmentStatus = value;
                        break;
                    case 'Personal Email':
                        input.emailPersonal = value;
                        break;
                    case 'Address':
                        const [street, city, state, zip] = value.split(', ');
                        input.addressStreet = street;
                        input.addressCity = city;
                        input.addressState = state;
                        input.addressZip = zip;
                        break;
                    case 'License Number':
                        input.driversLicenseNum = value;
                        break;
                    case 'License Expiration':
                        input.driversLicenseExpiration = new Date(value).toISOString();
                        break;
                    // case 'Year':
                    //     input.vehicleReference = input.vehicleReference || [{}]; // Initialize if empty
                    //     input.vehicleReference[0].year = value;
                    //     break;
                    case 'Name':
                        input.emergencyContactName = value;
                        break;
                    case 'Relationship':
                        input.relationshipToEmployee = value;
                        break;
                    case 'Phone':
                        input.emergencyContactPhone = value;
                        break;
                    default:
                        break;
                }
            });
        });
    
        return input;
    };
    
    //function to update employee data based on user input from display data
    const handleUpdateEmployee = async (updatedData:any) => {
        console.log('Updated data:', updatedData);
        try {
            const input = mapToEmployeeInput(updatedData);  // Map to expected input format
            console.log('Mapped input:', input);
            
            await updateEmployee({
                variables: { id, input },
            });
    
            console.log('Employee updated successfully');
            refetch();  // Optionally refetch data after update
        } catch (err) {
            console.error('Failed to update employee', err);
        }
    };
    
    //the following code is for the badge status, determined by DRIVER ONBAORDING program status
    const email = dataEmployee.getEmployeeById.emailPersonal;
    console.log('email', email);
    const programs = dataEmployee.getEmployeeById.program;
    const driverOnboardingProgram = programs.find((program: any) => program.name === 'Mobile Driver Onboard');
    let programStatus;
    if (driverOnboardingProgram) {
        programStatus = driverOnboardingProgram.status;
    }
   
    let fitStatus
    if(programStatus === 'ACTIVE'){
        fitStatus = "NEEDS ATTENTION";
    }else if(programStatus === 'INACTIVE'){
        fitStatus = "IN GOOD STANDING";
    }
    const statusClass = fitStatus === "IN GOOD STANDING" ? "status-fit" : "status-unfit";

    const badgeStatus = fitStatus === "IN GOOD STANDING" ? 'fit' : 'unfit';

    const checkAndStyleEmpty = (value: any, isDate = false) => {
        if (!value) {
            return <span style={{ color: 'red' }}>Not filled</span>;
        }
    
        // Handle date values
        if (isDate && typeof value === 'string' && Date.parse(value)) {
            return formatDate(value); // Format the date properly
        }
        
        return value;
    };
    
    const formatAddress = (...parts: any[]) => {
        const filteredParts = parts.filter(part => part);
        if (filteredParts.length > 0) {
            return filteredParts.join(', ');
        } else {
            return <span style={{ color: 'red' }}>Not filled</span>;
        }
    };
    
    const getCurrentDateFormatted = () => {
        const today = new Date();
        return today.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

  const informationData = {
        sections: [
            {
                title: 'Driver Information',
                data: [
                    { label: 'Employee Name', value: checkAndStyleEmpty(`${dataEmployee.getEmployeeById.firstName} ${dataEmployee.getEmployeeById.lastName}`) },
                    { label: 'Position', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.position) },
                    { label: 'Gender', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.gender) },
                    { label: 'Date of Birth', value: checkAndStyleEmpty(formatDate(dataEmployee.getEmployeeById.dob)) },
                    { label: 'Marital Status', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.maritalStatus) },
                    { label: 'Hire Date', value: checkAndStyleEmpty(formatDate(dataEmployee.getEmployeeById.startDate)) },
                    // { label: 'Stop Date', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.stopDate) },
                    { label: 'Employment Status', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.employmentStatus) },
                ],
            },
            {
                title: 'Contact Information',
                data: [
                    { label: 'Personal Email', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.emailPersonal) },
                    // { label: 'Company Email', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.emailCompany) },
                    // { label: 'Phone', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.phone) },
                    { label: 'Address', value: formatAddress(dataEmployee.getEmployeeById.addressStreet, dataEmployee.getEmployeeById.addressCity, dataEmployee.getEmployeeById.addressState, dataEmployee.getEmployeeById.addressZip) },
                ],
            },
            {
                title: 'Driver\'s License Information',
                data: [
                    { label: 'License Number', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.driversLicenseNum) },
                    { label: 'License Expiration', value: checkAndStyleEmpty(formatDate(dataEmployee.getEmployeeById.driversLicenseExpiration)) },
                ],
            },
                // Check if vehicleReference exists and contains at least one vehicle
        dataEmployee.getEmployeeById.vehicleReference && dataEmployee.getEmployeeById.vehicleReference.length > 0 ? {
            title: 'Vehicle Information',
            data: [
                { label: 'Year', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].year) },
                { label: 'Make', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].make) },
                { label: 'Model', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].model) },
                { label: 'Series', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].series) },
                { label: 'VIN', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].vin) },
            ],
        } : {
            title: 'Vehicle Information',
            data: [
                { label: 'Vehicle', value: 'No vehicle information available' },
            ],
        },
            {
                title: 'Emergency Contact Information',
                data: [
                    { label: 'Name', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.emergencyContactName) },
                    { label: 'Relationship', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.relationshipToEmployee) },
                    { label: 'Phone', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.emergencyContactPhone) },
                ],
            },
        ],
    };

    //defines navigation items 
    const navBarItems: NavBarItem[] = [
        { label: 'PROFILE', href: `/employeedetail/`, onClick: (event) => handleNavItemClick('PROFILE', event) },
        { label: 'DOCUMENTS', href: `/employeedetail/`, onClick: (event) => handleNavItemClick('DOCUMENTS', event) },
        { label: 'LOCATIONS', href: `/employeedetail/`, onClick: (event) => handleNavItemClick('LOCATIONS', event) },
        { label: 'RECORD LOGS', href: `/employeedetail/`, onClick: (event) => handleNavItemClick('RECORD LOGS', event) },
    ];

    const hasDocuments = dataEmployee.getEmployeeById.documentReference && dataEmployee.getEmployeeById.documentReference.length > 0;
    console.log('hasDocuments', hasDocuments);
    // Prepare rowData by mapping over the documentReference array
    const rowData = hasDocuments? dataEmployee.getEmployeeById.documentReference.map((doc: any, index: any) => ({
        documentType: doc.documentType,
        presignedUrl: <DocumentUrlComponent s3Bucket={doc.s3Bucket} s3Key={doc.s3Key} display="small" />, 
        documentId: doc.documentId,
    }))
    : [];

    const columnDefs=[
        { field: 'documentType', headerName: 'Type', flex: 1 },  
        { field: 'presignedUrl', headerName: 'Preview', flex: 1, cellRenderer: (params: any) => params.value  }, 
        { field: 'documentId', headerName: 'ID', flex: 1 },  
        // { field: 'size', headerName: 'Size', flex: 1 },  
        // { field: 'uploadDate', headerName: 'Upload Date', flex: 1 },
        // { field: 'uploadedBy', headerName: 'Uploaded By', flex: 1 },  
    ]

    const columnDefsLocations = [
        { field: 'locationName', headerName: 'Name', flex: 1 },
        { field: 'locationAddress', headerName: 'Address', flex: 1 },
        { field: 'locationCity', headerName: 'City', flex: 1 },
        { field: 'locationState', headerName: 'State', flex: 1 },
        { field: 'locationZip', headerName: 'Zip', flex: 1 },
        { field: 'locationManager', headerName: 'Manager', flex: 1 },
    ]
    const rowDataLocations = dataEmployee.getEmployeeById.locationReference.map((loc: any) => ({
        locationName: loc.name, 
        locationAddress: loc.addressStreet,
        locationCity: loc.addressCity,
        locationState: loc.addressState,
        locationZip: loc.addressZip,
        // locationManager: loc.locManagerName
    }))

    const handleNavItemClick = (view: string, event: React.MouseEvent) => {
        event.preventDefault();
        setCurrentView(view);
    };

    const handleDriversBackClick = () => {
        nav('/employeepage');
    };

    const handleRowClick = (event: any) => {
        setSelectedRowData(event.data);  // Save clicked row data
        setIsSlideOverOpen(true);  // Open RightSlideOver3
    };

    const closeSlideOver = () => {
        setIsSlideOverOpen(false);
        setIsUploadSlideOverOpen(false);
    };
    const openSlideOver = () => {
        setIsSlideOverOpen(true);
    }

    const handlePageTitleClick = () => {
        setCurrentView('PROFILE');
        refetch();
    };
    const onAddButtonClick = () => {
        console.log('Add button clicked');
        setAddActiveButton(true)
    }
    const closeAddButtonOverlay = () => {
        setAddActiveButton(false)
    }
    const onEmailClick = () => {
        setAddActiveButton(false)
        setIsSlideOverOpen(true)
    }
    const onUploadClick = () => {
        setIsUploadSlideOverOpen(true)
        setAddActiveButton(false)
    }
    return (
        <div>
            {/* {addActiveButton && <div className="dark-overlay" onClick={closeAddButtonOverlay}></div>} */}
            <MainTemplate />
            <div className="main-container">
            <div className="header">
                <PageBackButton onClick={handleDriversBackClick} name="Drivers" />
                <div className="title-icons-container">
                    <span className="locations-icon">
                        <EmployeesIcon />
                    </span>
                    <button className="page-title-button" onClick={handlePageTitleClick}>
                        <PageTitle pageTitle={`${dataEmployee.getEmployeeById.firstName} ${dataEmployee.getEmployeeById.lastName}`} />
                        <AddButtonDetailDrivers onClick={onAddButtonClick}/>
                        {addActiveButton && <div className="dark-overlay" onClick={closeAddButtonOverlay}></div>}
                        {addActiveButton && (
                            <div className="additional-buttons-container">
                            <div style={{ display: "flex", gap: '.5rem' }}>
                              <button
                                onClick={onEmailClick}
                                className=""
                                style={{
                                  borderRadius: "50%",
                                  backgroundColor: "#f5f5f5",
                                  padding: "1rem",
                                  width: "50px", // Ensures the button is circular
                                  height: "50px", // Ensures the button is circular
                                  display: "flex", // Align icon
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center", // Center horizontally
                                  transition: "transform 0.2s ease", // Smooth transition for scaling
                                }}
                                onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'} // Enlarge on hover
                                onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'} // Return to normal size
                              >
                                <EnvelopeIcon style={{ width: "20px", height: "20px" }} />
                              </button>
                              <button
                                className="additional-button"
                                style={{
                                  display: "flex", // Align text
                                  justifyContent: "center", // Center text horizontally
                                  alignItems: "center", // Center text vertically
                                  width: "100px", // Adjust as needed
                                  height: "50px", // Ensure it matches the circular button
                                }}
                                onClick={onEmailClick}
                              >
                                Email
                              </button>
                            </div>
                            <div style={{ display: "flex", gap: '.5rem', marginTop: '1rem' }}>
                              <button
                                className=""
                                style={{
                                  borderRadius: "50%",
                                  backgroundColor: "#f5f5f5",
                                  padding: "1rem",
                                  width: "50px", // Ensures the button is circular
                                  height: "50px", // Ensures the button is circular
                                  display: "flex", // Align icon
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center", // Center horizontally
                                  transition: "transform 0.2s ease", // Smooth transition for scaling
                                }}
                                onClick={onUploadClick}
                                onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'} // Enlarge on hover
                                onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'} // Return to normal size
                              >
                                <DocumentIcon style={{ width: "20px", height: "20px" }} />
                                
                              </button>
                              <button
                                className="additional-button"
                                style={{
                                  display: "flex", // Align text
                                  justifyContent: "center", // Center text horizontally
                                  alignItems: "center", // Center text vertically
                                  width: "100px", // Adjust as needed
                                  height: "50px", // Ensure it matches the circular button
                                }}
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                          
                          
                        )}
                    </button>
                </div>
            </div>
            <div>
                {/* <BadgeFlatWithDot title={<span className={`text-xs ${statusClass}`}>{fitStatus}</span>} status={badgeStatus} /> */}
                <BadgeFlatWithDot title={<span className={`text-xs ${statusClass}`}>{fitStatus}</span>} status={badgeStatus} />

                <div>
                    <HorizontalNavBar items={navBarItems} />
                </div>
                {currentView === 'RECORD LOGS' && (
                    <div className="info-component-employee-detail" style={{width:'100%', height:'700%'}}>
                         <Table columnDefs={columnDefsRecords} rowData={rowDataRecords} />
                    </div>
                )}
            {/* Conditionally render SlideOverNav for DOCUMENTS */}
            {currentView === 'DOCUMENTS' && (
                <SlideOverNav
                    activeView={activeView}
                    setActiveView={setActiveView}
                    navItems={[
                        { label: 'Uploaded Documents', viewId: 'uploadedDocuments' },
                        { label: 'Other Documents', viewId: 'otherDocuments' }
                    ]}
                    clickable={true}
                />
            )}
                <div className="info-component-employee-detail">
                    {currentView === 'PROFILE' && <InformationComponent sections={informationData.sections} employeeId={'67002cc8e40b9193d2281fa8'} onUpdateInformation={handleUpdateEmployee}/>}
                </div>
                    {/* {currentView === 'PROGRAMS' && activeView === 'driverOnboarding' && (
                    <div className="feed-checklist">
                        <div className="checklist">
                          <FfdEmployeeChecklist onItemClicked={function (index: number): void {
                                    throw new Error('Function not implemented.');
                                } } rowItems={[]}  />

                        </div>
                        <div className="feed">
                            <h1></h1>
                            <div className={`status-rectangle ${fitStatus === "IN GOOD STANDING" ? 'complete' : 'incomplete'}`}>
                                {fitStatus === "IN GOOD STANDING" ? <CheckIcon className="icon" /> : <XMarkIcon className="icon" />}
                                <span>{fitStatus === "IN GOOD STANDING" ? `Completed on ${getCurrentDateFormatted()}` : `Incomplete as of ${getCurrentDateFormatted()}`}</span>
                            </div>
                            <Feed />
                        </div>
                      </div>
                    )}                     */}
                    {currentView === 'DOCUMENTS' && activeView === 'uploadedDocuments' && (
                    <>
                        <div style={{height:'360%'}}>
                            {dataEmployee.getEmployeeById.documentReference && dataEmployee.getEmployeeById.documentReference.length > 0 ? (
                                <Table columnDefs={columnDefs} rowData={rowData}  onRowClick={handleRowClick}/>
                            ) : (
                                <p>No documents available</p> 
                            )}
                        {isSlideOverOpen && (
                            <RightSlideOver3 
                                closePopover={closeSlideOver} 
                                title={'FILE PREVIEW'}  
                                documentId={selectedRowData.documentId}                            
                            />
                        )}
                        </div>
                    </>
                    )}
                    {currentView === 'LOCATIONS' && (
                        <div style={{height:'700%'}}>
                            <Table columnDefs={columnDefsLocations} rowData={rowDataLocations} />
                        </div>
                        
                    )}
                        <BaseSlideOver 
                            closePopover={closeSlideOver} 
                            title={'SEND EMAIL'}
                            isOpen={isSlideOverOpen} 
                            email={email}                           
                        />
                        <BaseSlideOver 
                            closePopover={closeSlideOver} 
                            title={'UPLOAD DOCUMENT'}
                            isOpen={isUploadSlideOverOpen}
                        />
                </div>
            </div>
        </div>
    );
};

export default EmployeeDetail;
